import type { FC, MouseEvent } from "react";
import React, { useState } from "react";

import { ArrowDropDown } from "@mui/icons-material";
import type { Breakpoint, MenuItemProps, SxProps } from "@mui/material";
import { Menu, MenuItem } from "@mui/material";
import { AppBar, Button, Container, Stack, Toolbar } from "@mui/material";
import { grey } from "@mui/material/colors";
import Link from "next/link";
import { useRouter } from "next/router";

import { IRISLogo } from "@/components/layouts/IRISLogo";
import { UserMenu } from "@/components/menus/UserMenu";
import { useUserInfo } from "@/hooks/useUserInfo";
import { isAnalyst } from "@/utils/user-utils";

interface MainLinkProps {
  title: string;
  href: string;
}

const MainLink = (props: MainLinkProps) => {
  const router = useRouter();

  return (
    <Button
      href={props.href}
      data-test={`navigate-${props.title}`}
      LinkComponent={Link}
      color="inherit"
      sx={{
        backgroundColor: "#FFFFFF",
        fontSize: "1rem",
        letterSpacing: 0,
        borderBottom: "3px solid red",
        borderRadius: 0,
        ...(router.pathname !== props.href && {
          color: grey[500],
          borderBottom: "none",
        }),
      }}
    >
      {props.title}
    </Button>
  );
};

const SystemMenu: FC = () => {
  const router = useRouter();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (href: string) => {
    router.push(href);
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="system-button"
        aria-controls={open ? "system-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        color="inherit"
        sx={{
          backgroundColor: "#FFFFFF",
          fontSize: "1rem",
          letterSpacing: 0,
          borderRadius: 0,
          color: grey[500],
        }}
        endIcon={<ArrowDropDown />}
      >
        System
      </Button>
      <Menu
        id="system-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "system-button",
        }}
        elevation={1}
      >
        <MenuItem onClick={() => handleClose("/archetypes")}>
          Archetypes
        </MenuItem>
      </Menu>
    </>
  );
};

interface MainMenuItemProps extends MenuItemProps {
  href?: string;
}

const MainMenuItem: FC<MainMenuItemProps> = (props) => {
  const { onClick, children } = props;
  const router = useRouter();

  const handleOnClick = (event: MouseEvent<HTMLLIElement>) => {
    if (props.href) {
      router.push(props.href);
    }
    if (onClick) {
      onClick(event);
    }
  };

  return <MainMenuItem onClick={handleOnClick}>{children}</MainMenuItem>;
};

export interface PageLayoutProps {
  backgroundColor?: string;
  children: React.ReactNode | React.ReactNodeArray;
  maxWidth?: Breakpoint | false;
  sx?: SxProps;
}

export const PageLayout = (props: PageLayoutProps) => {
  const { children, backgroundColor, maxWidth = "xl", sx } = props;
  const { data } = useUserInfo();
  const router = useRouter();

  return (
    <>
      <AppBar
        position="sticky"
        color="inherit"
        variant="outlined"
        elevation={0}
      >
        <Toolbar variant="dense">
          <Stack
            data-test="navigate-Home"
            direction="row"
            spacing={0.25}
            onClick={() => router.push("/")}
            onMouseOver={() => router.prefetch("/")}
            sx={{
              minWidth: 175,
              cursor: "pointer",
              alignItems: "center",
            }}
          >
            <IRISLogo />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ flexGrow: 1 }}>
            {data?.user?.organization && (
              <>
                <MainLink title="Portfolio" href="/" />
                <MainLink title="Assets" href="/assets" />
                {isAnalyst(data?.user?.roles) && (
                  <MainLink title="Assessments" href="/assessments" />
                )}
                <MainLink title="Recommendations" href="/recommendations" />
                {isAnalyst(data?.user?.roles) && <SystemMenu />}
              </>
            )}
          </Stack>
          <UserMenu />
        </Toolbar>
      </AppBar>
      <Container
        maxWidth={maxWidth}
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          pb: 4,
          px: 0,
          minHeight: "calc(100vh - 56px)",
          "*::-webkit-scrollbar": {
            width: "0.3em",
            height: "0.3em",
            px: 4,
          },
          backgroundColor,
          ...sx,
        }}
      >
        {children || null}
      </Container>
    </>
  );
};

export default PageLayout;
