import React from "react";

import type { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faFaceMehBlank } from "@fortawesome/free-solid-svg-icons/faFaceMehBlank";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";

import {
  RiskRatingHazardsEnum,
  SubHazardType,
} from "@/apis/services/HazardService";
import { HAZARD_ICONS } from "@/constants/hazardIcons";
import { AGGREGATE } from "@/constants/risks";
import { getHazardDisplayLabel } from "@/utils/display-label-utils";

export interface RiskRatingIconProps {
  icon: string;
  size?: SizeProp;
  disableTooltip?: boolean;
  color?: string;
}

export const RiskRatingIcon = ({
  icon,
  size,
  disableTooltip,
  color,
}: RiskRatingIconProps) => {
  const getTooltip = () => {
    if (
      icon === RiskRatingHazardsEnum.HydrologicalFlooding ||
      icon === SubHazardType.INTEGRATED_FLOODING
    )
      return "This incorporates all sources of flooding (i.e. stormwater, riverine, coastal, etc.) into a single hazard.";
    if (icon === AGGREGATE)
      return "The cumulative risk of all assessed hazards for a given consequence, based on average annual loss (AAL).";
    return getHazardDisplayLabel(icon);
  };

  return (
    <Tooltip title={getTooltip()} disableHoverListener={disableTooltip}>
      {/* This div is needed to make the tooltip appear -.- */}
      <div>
        <FontAwesomeIcon
          size={size ?? "lg"}
          icon={HAZARD_ICONS[icon] || faFaceMehBlank}
          color={color ?? "grey"}
          width={25}
        />
      </div>
    </Tooltip>
  );
};
